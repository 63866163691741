import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import SetPassword from './auth-views/authentication/set-password'
import NotFound from './auth-views/errors/error-page-1'
import AuthLayout from 'layouts/auth-layout'
import AppLocale from 'lang'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const Views = (props) => {
  const { locale, token, location } = props
  const currentAppLocale = AppLocale[locale]
  console.DisableYellowBox = true;
  return (
    <IntlProvider
    
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            <Redirect to={AUTH_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout location={location} />
          </Route>
          <Route path="/set-password">
            <SetPassword />
          </Route>
          <Route path="/404">
            <NotFound />
          </Route>
          <Redirect from="*" to="/404" />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme
  const { token } = auth
  return { locale, token }
}

export default withRouter(connect(mapStateToProps)(Views))
