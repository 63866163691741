import React, { useState } from 'react'
import { Card, Row, Col, Form, Input, Button, message } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { auth } from '../../../../firebase'

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

const ForgotPassword = () => {
  let history = useHistory()

  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  })

  const handle = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSend = () => {
    if (
      formData.email === '' ||
      formData.password === '' ||
      formData.confirmPassword === ''
    ) {
      message.warning('Please fill in all fields!')
      return
    }

    if (formData.password !== formData.confirmPassword) {
      message.warning('Entered passwords do not match!')
      return
    }

    if (formData.password.length < 8) {
      message.warning('Please enter a password with minimum 8 characters!')
      return
    }

    setLoading(true)

    auth
      .signInWithEmailAndPassword(formData.email, 'password123')
      .then(() => {
        setLoading(false)

        let user = auth.currentUser

        user
          .updatePassword(formData.password)
          .then(() => {
            // console.log('password updated')
            message.success('Password successfully changed.')

            fetch(
              `https://us-central1-reib-platform.cloudfunctions.net/app/api/retrieve-claim/${user.email}`,
            )
              .then((response) => {
                if (response.ok) {
                  return response.json()
                } else {
                  throw Error('Error fetching data.')
                }
              })
              .then((claim) => {
                if (claim.memberUser) {
                  history.push(`/app/member-profiles`)
                } else if (claim.reibSuperAdmin || claim.reibAdmin) {
                  history.push(`/app`)
                } else if (claim.companyAdmin || claim.companyUser) {
                  history.push(`/app/dashboards/sales`)
                }
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            // console.log(error)
          })
      })
      .catch((error) => {
        // console.log(error)
        setLoading(false)
      })
  }

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={9}>
            <Card style={{ marginTop: '18px' }}>
              <div className="my-2">
                <div className="text-center">
                  <img className="img-fluid" src="/img/logo.png" alt="" />
                  <h3 className="mt-3 font-weight-bold">Set Password</h3>
                  <p className="mb-4">
                    Enter your email and set a password for your REIB platform
                    account
                  </p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form layout="vertical" name="set-password">
                      <Form.Item style={{ marginBottom: '11px' }}>
                        <Input
                          placeholder="Email Address"
                          type="email"
                          prefix={<MailOutlined className="text-primary" />}
                          name="email"
                          value={formData.email}
                          onChange={handle}
                        />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: '11px' }}>
                        <Input
                          placeholder="Enter Password"
                          prefix={<LockOutlined className="text-primary" />}
                          name="password"
                          type="password"
                          value={formData.password}
                          onChange={handle}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          placeholder="Confirm Password"
                          prefix={<LockOutlined className="text-primary" />}
                          name="confirmPassword"
                          type="password"
                          value={formData.confirmPassword}
                          onChange={handle}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          loading={loading}
                          type="primary"
                          onClick={onSend}
                          block
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ForgotPassword
