import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/`}
          component={lazy(() => import(`./authentication/login-2`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/logout`}
          component={lazy(() => import(`./authentication/logout`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/register`}
          component={lazy(() => import(`./authentication/register-2`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/set-password`}
          component={lazy(() => import(`./authentication/set-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/error-1`}
          component={lazy(() => import(`./errors/error-page-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/404`}
          component={lazy(() => import(`./errors/error-page-2`))}
        />
        <Redirect from="*" to={`${AUTH_PREFIX_PATH}/404`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews
