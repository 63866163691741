import React, { useState } from 'react'
import { SettingOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Drawer, Menu, notification, Popconfirm } from 'antd'
import ThemeConfigurator from './ThemeConfigurator'
import { connect } from 'react-redux'
import { auth } from '../../firebase'
import { useHistory } from 'react-router-dom'

export default () => {
  let history = useHistory()
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const SignOut = () => {
    auth
      .signOut()
      .then(() => {
        history.push('/auth/logout')
        // console.log('done')
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item onClick={showDrawer}>
          <SettingOutlined className="nav-icon mr-0" />
        </Menu.Item>
        <Menu.Item onClick={null}>
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to log out of your session?"
            onConfirm={SignOut}
            okText="Yes"
            cancelText="No"
          >
            <PoweroffOutlined className="nav-icon mr-0" />
          </Popconfirm>
        </Menu.Item>
      </Menu>
      <Drawer
        title="Theme Config"
        placement="right"
        width={350}
        onClose={onClose}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  )
}
