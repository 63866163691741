import firebase from 'firebase/app'
import firebase2 from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBoEVYfnbJf73PKGZ53-UdrB5wh_A_Tkks',
  authDomain: 'reib-platform.firebaseapp.com',
  projectId: 'reib-platform',
  databaseURL: 'https://reib-platform.firebaseio.com',
  storageBucket: 'reib-platform.appspot.com',
  messagingSenderId: '135757949875',
  appId: '1:135757949875:web:2566a06a0070db9ff0f9c6',
  measurementId: 'G-TLE9XLMRWF',
}

firebase.initializeApp(firebaseConfig)

export const logAction = (email, action, region) => {
  const currentdate = new Date()
  const datetime =
    currentdate.getDate() +
    '/' +
    (currentdate.getMonth() + 1) +
    '/' +
    currentdate.getFullYear() +
    ' @ ' +
    currentdate.getHours() +
    ':' +
    currentdate.getMinutes() +
    ':' +
    currentdate.getSeconds()

  // Firestore function to activity by user:
  firebase
    .firestore()
    .collection('activity_log')
    .doc()
    .set({
      user: email,
      action: action,
      region: region,
      time: datetime,
    })
    .then(() => {
      // console.log('Document successfully written!')
    })
    .catch((error) => {
      console.error('Error writing document: ', error)
    })
}

export const sendResetLink = (email) => {
  let actionCodeSettings = {
    url: 'https://reib-platform.web.app/set-password?mode=action&oobCode=code',
    handleCodeInApp: true,
  }

  firebase
    .auth()
    .sendPasswordResetEmail(email, actionCodeSettings)

    .then(function () {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      // console.log('email notification sent!!!!!')
    })
    .catch(function (error) {
      // console.log(error)
      // Some error occurred, you can inspect the code: error.code
    })
}

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const storage = firebase.storage()

export const persist = firebase.auth.Auth.Persistence.LOCAL

export const firebaseApp = firebase2.initializeApp(
  firebaseConfig,
  'create-user',
)
