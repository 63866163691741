import {
  DashboardOutlined,
  DollarOutlined,
  SolutionOutlined,
  ReadOutlined,
  HomeOutlined,
  IdcardOutlined,
  FileSearchOutlined,
  FlagOutlined,
  TeamOutlined,
  FundOutlined,
  UserAddOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons'
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

const reibSuperAdminNavTree = [
  {
    key: 'reib-staff',
    title: 'REIB Staff',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'home',
        path: `${APP_PREFIX_PATH}/`,
        title: 'Dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'user-management',
        path: `${APP_PREFIX_PATH}/user-management`,
        title: 'User Management',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'members',
        path: `${APP_PREFIX_PATH}/Members-admin-dashboard`,
        title: 'Members',
        icon: IdcardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'Admin_payments',
        path: `${APP_PREFIX_PATH}/Payments_Admin`,
        title: 'Payments',
        icon: DollarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'applications',
        path: `${APP_PREFIX_PATH}/applications`,
        title: 'Membership Applications',
        icon: UserAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
     
      {
        key: 'admin-jobs',
        path: `${APP_PREFIX_PATH}/job-listing-request`,
        title: 'Listed Jobs',
        icon: FileSearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'properties2',
        path: `${APP_PREFIX_PATH}/kyc-docs`,
        title: 'KYC Documents',
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports`,
        title: 'Crime Reports',
        icon: FlagOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'news',
        path: `${APP_PREFIX_PATH}/news`,
        title: 'Latest News',
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
]

const reibAdminNavTree = [
  {
    key: 'reib-staff',
    title: 'REIB Staff',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'home',
        path: `${APP_PREFIX_PATH}/`,
        title: 'Dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'members',
        path: `${APP_PREFIX_PATH}/Members-admin-dashboard`,
        title: 'Members',
        icon: IdcardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'applications',
        path: `${APP_PREFIX_PATH}/applications`,
        title: 'Membership Applications',
        icon: UserAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-jobs',
        path: `${APP_PREFIX_PATH}/job-listing-request`,
        title: 'Listed Jobs',
        icon: FileSearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'properties2',
        path: `${APP_PREFIX_PATH}/kyc-docs`,
        title: 'KYC Documents',
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports`,
        title: 'Crime Reports',
        icon: FlagOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'news',
        path: `${APP_PREFIX_PATH}/news`,
        title: 'Latest News',
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
]

const companyNavTree = [
  {
    key: 'company',
    title: 'Companies',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: 'Dashboard',
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'jobs',
        path: `${APP_PREFIX_PATH}/jobs`,
        title: 'Listed Jobs',
        icon: FileSearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
]

const membersNavTree = [
  {
    key: 'properties',
    title: 'Members',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'mem-profiles',
        path: `${APP_PREFIX_PATH}/member-profiles`,
        title: 'Member Status',
        icon: HomeOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'properties1',
        path: `${APP_PREFIX_PATH}/my-kyc`,
        title: 'KYC Forms',
        icon: SolutionOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'payments',
        path: `${APP_PREFIX_PATH}/payments`,
        title: 'Payments',
        icon: DollarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'job',
        path: `${APP_PREFIX_PATH}/job`,
        title: 'Jobs',
        icon: FileSearchOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
]

const blankNavTree = []

const fraudsNavTree = [
  {
    key: 'fraud',
    title: 'Crimes',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'reports',
        path: `/`,
        title: 'Reports',
        icon: SecurityScanOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'black-list',
            path: `/`,
            title: 'Black Listed',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'tipoff',
            path: `/`,
            title: 'Tip Offs',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
]

const KycNavTree = [
  {
    key: 'kyc',
    title: 'KYC',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'logs',
        path: `/`,
        title: 'KYC logs',
        icon: SecurityScanOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'black-list',
            path: `/`,
            title: 'Black Listed',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
]

const navigationConfig = [
  ...reibSuperAdminNavTree,
  ...reibAdminNavTree,
  ...companyNavTree,
  ...membersNavTree,
]

export const superAdminConfig = [...reibSuperAdminNavTree]

export const adminConfig = [...reibAdminNavTree]

export const companyConfig = [...companyNavTree]

export const memberConfig = [...membersNavTree]

export const blankConfig = [...blankNavTree]

export default navigationConfig
