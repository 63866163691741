import React, { lazy, Suspense, useContext } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { UserContext } from "../../provider/UserProvider";

export const AppViews = () => {
  const { currentUser } = useContext(UserContext);

  let history = useHistory();

  const redirectLogin = () => {
    history.push(``);
  };

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {!!currentUser ? (
          <Route
            path={`${APP_PREFIX_PATH}/`}
            component={lazy(() => import(`./home`))}
            exact
          />
        ) : (
          <Redirect to="/" />
        )}

        <Route
          path={`${APP_PREFIX_PATH}/company-home`}
          component={lazy(() => import(`./home-company`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/Members-admin-dashboard`}
          component={lazy(() => import(`./Members-admin-dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member-profile/:id`}
          component={lazy(() => import(`./members/member-profile`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/member-profiles`}
          component={lazy(() => import(`./member-profiles`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/payments`}
          component={lazy(() => import(`./member-profiles/paypal-integration`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/member/:id`}
          component={lazy(() => import(`./member-profiles/member`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/applications`}
          component={lazy(() => import(`./applications`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./applications/profile`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/pro`}
          component={lazy(() => import(`./applications/pro`))}
        />
        {/* //changes */}
        <Route
          path={`${APP_PREFIX_PATH}/probationerProfile`}
          component={lazy(() => import(`./applications/probationerProfile`))}
        />
        {/* end */}
        <Route
          path={`${APP_PREFIX_PATH}/profiles`}
          component={lazy(() => import(`./member-profiles/profiles`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/profiles1`}
          component={lazy(() => import(`./Members-admin-dashboard/profiles1`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/profileMember`}
          component={lazy(() => import(`./Members-admin-dashboard/profileMember`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/applications/application`}
          component={lazy(() =>
            import(
              `./applications/ui/card/project/project-list/ProjectListData`
            )
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/kyc-docs`}
          component={lazy(() => import(`./properties2`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/add-property2`}
          component={lazy(() => import(`./properties2/add-property2`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/property-details2/:id`}
          component={lazy(() => import(`./properties2/property-details2`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/property-details2`}
          component={lazy(() => import(`./properties2`))}
        />

        {/* changes */}
        <Route
          path={`${APP_PREFIX_PATH}/my-kyc`}
          component={lazy(() => import(`./properties1`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/add-kyc`}
          component={lazy(() => import(`./properties1/add-property1`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/property-details1/:id`}
          component={lazy(() => import(`./properties1/property-details1`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/news/:id`}
          component={lazy(() => import(`./admin_news/newsDetails`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/property-details1`}
          component={lazy(() => import(`./properties1`))}
        />

        {/* end */}

        <Route
          path={`${APP_PREFIX_PATH}/properties`}
          component={lazy(() => import(`./properties`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/news`}
          component={lazy(() => import(`./admin_news`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/property-details/:id`}
          component={lazy(() => import(`./properties/property-details`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/property-details-admin/:id`}
          component={lazy(() => import(`./admin_properties/property_detail`))}
        />
        {/* change */}
        <Route
          path={`${APP_PREFIX_PATH}/property-detail-admin/:id`}
          component={lazy(() => import(`./admin_properties/property_details`))}
        />
        {/* end */}
        <Route
          path={`${APP_PREFIX_PATH}/add-property`}
          component={lazy(() => import(`./properties/add-property`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/jobs`}
          component={lazy(() => import(`./jobs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/job-listing-request`}
          component={lazy(() => import(`./admin-jobs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/job-details/:id`}
          component={lazy(() => import(`./jobs/job-details`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/add-job`}
          component={lazy(() => import(`./jobs/add-job`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/user-management`}
          component={lazy(() => import(`./user-management`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/Payments_Admin`}
          component={lazy(() => import(`./admin_Payments`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/job`}
          component={lazy(() => import(`./job`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/job-detail/:id`}
          component={lazy(() => import(`./job/job-detail`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/reports`}
          component={lazy(() => import(`./admin_reports`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/kyc`}
          component={lazy(() => import(`./kyc`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/valuations`}
          component={lazy(() => import(`./valuations`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/company-members`}
          component={lazy(() => import(`./companyMembers`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/edit-product/:id`}
          component={lazy(() => import(`./companyMembers/edit-product`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/companyMembers`}
          component={lazy(() => import(`./companyMembers`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/properties_list`}
          component={lazy(() => import(`./admin_properties`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/properties_detail`}
          component={lazy(() => import(`./admin_properties/property_detail`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} /> */}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
